<template>
    <div class="bid_wrap license_wrap vote_detail item_detail_open_wrap">
        <div class="bid_main_wrap">
            <div class="bid_content_wrap">
                <div class="bid_title_wrap">
                    <h1 class="title">{{ $t('item') }}</h1>
                    <div class="status_change_wrap">
                        <button class="item_status_btn gift" @click="openGiftModal()"
                                v-if="checkGift()">
                            {{ $t('gift') }}
                        </button>
                        <div class="item_status_btn" v-if="checkUpdate()" @click="moveUpdate(`update`)"
                             :disabled="checkDisabled(itemData)">
                            {{ $t('status_change') }}
                        </div>
                    </div>

                    <div class="status_chang_text mt5" v-if="checkDisabled(itemData)">{{ $t('item_status_Msg') }}</div>
                </div>
                <div class="content_wrap">

                    <div class="img_wrap img_content_wrap">
                        <div class="b_img" v-loading="mainImgLoading" @mouseover="imageHover(true)"
                             @mouseleave="imageHover(false)">
                            <img v-if="spin" class="active" :src="returnSpinThumbnail(mainImg)" @load="isLoadMainImg"/>
                            <img v-else :src="returnThumbnail(mainImg)" @load="isLoadMainImg"/>
                            <i class="el-icon-refresh reload_icon" :class="{'hover':imgHover}"
                               @mouseover="imageHover(true)" @mouseleave="imageHover(false)" @click="spinImg()"></i>
                            <i class="el-icon-caret-right play_icon cursor" v-if="isPossiblePlay()"
                               @click="openPlayModal()"></i>
                        </div>

                        <swiper class="small_img_wrap" ref="swiper" :options="swiperOption" v-if="thumnailStatus"
                                :slidesPerView="'auto'" @click="onClick"
                                @slideChange="slideChange">
                            <swiper-slide class="s_img">
                                <img :src="returnSmallThumbnail(orgItemImg)" @load="isLoadMainImg"/>
                            </swiper-slide>
                            <swiper-slide class="s_img"
                                          v-for="(data,index) in thumnailImgs"
                                          :key="`detail${index}`"
                                          v-if="data.org_name != 'no_image.png'"
                            >
                                <img :src="returnSmallThumbnail(data.org_url)"/>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="inform_wrap inform_content_wrap">
                        <div class="inform_header">
                            <div class="i_header_top">
                                <div class="left">
                                    <span class="left_span ">{{ returnItemType(itemData) }}</span>
                                </div>
                                <div class="right">
                                    <div class="share_btn" @click="openShareModal"></div>
                                    <like-component :likeType="'itemDetail'" :likeData="itemData"></like-component>
                                    <div class="icon_wrap">
                                        <img class="icon eye" src="@/assets/image/main/eye1.png" alt="">
                                        <div class="icon_count">{{ Number(itemData.im_real_hit) }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="inform_h_header">
                                <ul class="thumb_img">
                                    <li>
                                        <el-popover
                                                :open-delay="500"
                                                :offset="0"
                                                @show="getMemberData(itemData,'im_originator')"
                                                :append-to-body="false"
                                                :popper-options="{
                          boundariesElement: 'body',
                          gpuAcceleration: true,
                          positionFixed: true,
                          preventOverflow: true,}"
                                                trigger="click"
                                                popper-class="bid_popover">
                                            <img slot="reference" class="card_profile"
                                                 :src="returnItemProfile(itemData,'originator')">
                                            <!--                                            <div class="popover_wrap" v-if="checkMemberData()"
                                                                                             @click="moveMember(`creator/${itemData.im_originator}`)">-->
                                            <div class="popover_wrap" v-if="checkMemberData()"
                                                 @click="moveMember(`creator/${memberData.mb_uuid}`)">
                                                <div class="popover_top">
                                                    <div class="popover_creator">
                                                        {{ $t('k_creator') }}
                                                    </div>
                                                    <div class="popover_nickname">
                                                        <img class="nickname_img" :src="returnMemberImg()">
                                                        <div class="popover_text_list">
                                                            <div class="popover_nickname_list">
                                                                <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                                <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                                    <span>Curator</span></div>
                                                                <div class="pop_curator second_color"
                                                                     v-if="memberData.mb_premium===1">
                                                                    <span>Special</span></div>
                                                            </div>
                                                            <div class="popover_nickname2">
                                                                <img class="pn_img"
                                                                     src="@/assets/image/main/heart1.png">
                                                                <div>{{ memberData.likeCnt }}</div>
                                                                <div>ITEM</div>
                                                                <div>{{ memberData.itemCnt }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="popover_bottom">
                                                    <div>
                                                        {{ memberData.mb_introduce }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="popover_wrap" v-else>
                                                <div class="popover_top no_profile">
                                                    <div class="popover_creator no_profile_creator">
                                                        {{ $t('k_creator') }}
                                                    </div>
                                                    <div class="popover_nickname no_profile_nickname">
                                                        <img class="nickname_img no_profile_img"
                                                             src="@/assets/image/main/no_profile.png">
                                                        <div class="popover_text_list no_profile_text">
                                                            <div class="popover_nickname_list">
                                                                <div class="nick_text no_profile_text">{{
                                                                        $t('notInfo')
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="popover_bottom no_profile_bottom">
                                                    <div class="pop_pover_div no_profile_div">
                                                        {{ $t('notProvider') }}
                                                    </div>
                                                </div>
                                            </div>
                                        </el-popover>
                                    </li>

                                    <li>
                                        <el-popover
                                                :open-delay="500"
                                                :offset="0"
                                                @show="getMemberData(itemData,'provider')"
                                                :append-to-body="false"
                                                :popper-options="{
                          boundariesElement: 'body',
                          gpuAcceleration: true,
                          positionFixed: true,
                          preventOverflow: true,}"
                                                trigger="click"
                                                popper-class="bid_popover">
                                            <img class="card_profile" slot="reference"
                                                 :src="returnItemProfile(itemData,'provider')">
                                            <!--                                            <div class="popover_wrap" v-if="checkMemberData()"
                                                                                             @click="moveMember(`creator/${itemData.provider}`)">-->
                                            <div class="popover_wrap" v-if="checkMemberData()"
                                                 @click="moveMember(`creator/${memberData.mb_uuid}`)">
                                                <div class="popover_top">
                                                    <div class="popover_creator">
                                                        {{ $t('owner') }}
                                                    </div>
                                                    <div class="popover_nickname">
                                                        <img class="nickname_img" :src="returnMemberImg()">
                                                        <div class="popover_text_list">
                                                            <div class="popover_nickname_list">
                                                                <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                                <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                                    <span>Curator</span></div>
                                                                <div class="pop_curator second_color"
                                                                     v-if="memberData.mb_premium===1">
                                                                    <span>Special</span></div>
                                                            </div>
                                                            <div class="popover_nickname2">
                                                                <img class="pn_img"
                                                                     src="@/assets/image/main/heart1.png">
                                                                <div>{{ memberData.likeCnt }}</div>
                                                                <div>ITEM</div>
                                                                <div>{{ memberData.itemCnt }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="popover_bottom">
                                                    <div>
                                                        {{ memberData.mb_introduce }}
                                                    </div>
                                                </div>
                                            </div>
                                        </el-popover>
                                    </li>
                                    <li v-if="!isEmpty(itemData.ibc_curator)">
                                        <el-popover
                                                :open-delay="500"
                                                :offset="0"
                                                @show="getMemberData(itemData,'ibc_curator')"
                                                :append-to-body="false"
                                                :popper-options="{
                          boundariesElement: 'body',
                          gpuAcceleration: true,
                          positionFixed: true,
                          preventOverflow: true,}"
                                                trigger="click"
                                                popper-class="bid_popover no_profile_popover">
                                            <img class="card_profile" slot="reference"
                                                 :src="returnItemProfile(itemData,'curator')">
                                            <!--                                            <div class="popover_wrap"
                                                                                             @click="moveMember(`creator/${itemData.ibc_curator}`)">-->
                                            <div class="popover_wrap"
                                                 @click="moveMember(`creator/${memberData.mb_uuid}`)">
                                                <div class="popover_top">
                                                    <div class="popover_creator">
                                                        {{ $t('curator') }}
                                                    </div>
                                                    <div class="popover_nickname">
                                                        <img class="nickname_img" :src="returnMemberImg()">
                                                        <div class="popover_text_list">
                                                            <div class="popover_nickname_list">
                                                                <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                                <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                                    <span>Curator</span></div>
                                                                <div class="pop_curator second_color"
                                                                     v-if="memberData.mb_premium===1">
                                                                    <span>Special</span></div>
                                                            </div>
                                                            <div class="popover_nickname2">
                                                                <img class="pn_img"
                                                                     src="@/assets/image/main/heart1.png">
                                                                <div>{{ memberData.likeCnt }}</div>
                                                                <div>ITEM</div>
                                                                <div>{{ memberData.itemCnt }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="popover_bottom">
                                                    <div>
                                                        {{ memberData.mb_introduce }}
                                                    </div>
                                                </div>
                                            </div>
                                        </el-popover>
                                    </li>
                                </ul>
                                <div class="time_remain" v-if="itemData.i_status === 'auction'">
                                    <span>{{ returnItemType(itemData) }}</span>
                                </div>
                            </div>

                            <div class="header_title">
                                <h3 class="title_left item">{{ returnCategoryName(itemData) }}</h3>
                                <div class="card_category cc_real" v-if="itemData.i_type === 'real'">{{
                                        $t('real')
                                    }}
                                </div>
                                <h3 class="title_right">{{ itemData.i_name }}</h3>
                            </div>

                            <div class="edition" v-if="itemData.im_edition === 1">
                                <span v-if="itemData.i_type === 'real'"><span>
                                    {{ $t('edition') }}</span> {{ itemData.im_count }} of {{ itemData.im_total }}</span>
                                <span v-else><span>{{ $t('edition') }}</span> {{ itemData.im_count }}</span>
                            </div>
                        </div>
                        <div class="inform_content">
                            <div class="hashtag_wrap">
                                <ul>
                                    <li v-for="(hash,index) in itemData.Hash" :key="`hash${index}`">
                                        <span v-if="hash.ih_type === 1">#{{ returnCategoryDetailName(hash) }}</span>
                                        <span v-else>#{{ hash.ih_hashtag }}</span>
                                    </li>
                                </ul>
                            </div>
                            <ul class="inform_detail_box">
                                <li class="detail_list">
                                    <div class="detail_tit">
                                        {{ $t('type') }}
                                    </div>
                                    <div class="detail_content">
                                        {{ returnType(itemData.i_type) }}
                                    </div>
                                </li>
                                <li class="detail_list">
                                    <div class="detail_tit">
                                        {{ $t('category') }}
                                    </div>
                                    <div class="detail_content">
                                        {{ returnCategoryName(itemData) }}
                                    </div>
                                </li>
                                <li class="detail_list" v-if="itemData.isEdition === 1">
                                    <div class="detail_tit">
                                        {{ $t('edition') }}
                                    </div>
                                    <div class="detail_content" v-if="itemData.type === 'real'">
                                        {{ itemData.editionCount }} of {{ itemData.editionTotal }}
                                    </div>
                                    <div class="detail_content" v-else>
                                        {{ itemData.editionCount }}
                                    </div>
                                </li>
                                <li class="detail_list">
                                    <div class="detail_tit">
                                        {{ $t('certi') }}
                                    </div>
                                    <!--                                    <div class="detail_content color_navy"
                                                                             v-for="(data,index) in itemData.ItemFile.Guaranty"
                                                                             :key="`guranty${index}`" v-if="checkImg(data)">
                                                                            {{ data.org_name }}
                                                                        </div>-->
                                    <div class="detail_content color_navy"
                                         v-if="checkImgData('Guaranty')"
                                         v-for="(data,index) in itemData.ItemFile.Guaranty"
                                         @click="awsS3Download('guaranty', data, itemData.i_idx)"
                                         :key="`guranty${index}`">
                                        {{ data.org_name }}
                                    </div>
                                    <div class="detail_content " v-else>
                                        {{ $t('no3') }}
                                    </div>
                                </li>
                                <li class="detail_list" v-if="itemData.i_type === 'digital'">
                                    <div class="detail_tit">
                                        {{ $t('f_real') }}
                                    </div>
                                    <div class="detail_content color_navy" v-if="checkImgData('NFT')">
                                        {{ itemData.ItemFile.NFT[0].a_filename }}
                                    </div>
                                </li>
                                <li class="detail_list">
                                    <div class="detail_tit">
                                        {{ $t('detail_inform') }}
                                    </div>

                                    <ul class="detail_content content_add" v-if="itemData.im_type==='photo'">
                                        <li v-if="checkItemData('imo_date')">
                                            <h3>{{ $t('t_video2') }}</h3>
                                            <span>{{ itemData.imo_date }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_country')">
                                            <h3>{{ $t('v_country2') }}</h3>
                                            <span>{{ itemData.imo_country }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_si')">
                                            <h3>{{ $t('v_si2') }}</h3>
                                            <span>{{ itemData.imo_si }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_city')">
                                            <h3>{{ $t('v_city2') }}</h3>
                                            <span>{{ itemData.imo_city }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_size')">
                                            <h3>{{ $t('size2') }}</h3>
                                            <span>{{ itemData.imo_size }}</span>
                                        </li>
                                        <!--                                        <li>
                                                                                    <h3>{{ $t('certi2') }}</h3>
                                                                                    <span v-if="checkCerti()">{{ $t('has') }}</span>
                                                                                    <span v-else>{{ $t('empty') }}</span>
                                                                                </li>-->
                                        <li v-if="checkItemData('imo_copyright')">
                                            <h3>{{ $t('copyright_info2') }}</h3>
                                            <span>{{ itemData.imo_copyright }}</span>
                                        </li>
                                    </ul>
                                    <ul class="detail_content content_add" v-else-if="itemData.im_type==='video'">
                                        <li v-if="checkItemData('imo_date')">
                                            <h3>{{ $t('t_video2') }}</h3>
                                            <span>{{ itemData.imo_date }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_country')">
                                            <h3>{{ $t('v_country2') }}</h3>
                                            <span>{{ itemData.imo_country }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_si')">
                                            <h3>{{ $t('v_si2') }}</h3>
                                            <span>{{ itemData.imo_si }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_city')">
                                            <h3>{{ $t('v_city2') }}</h3>
                                            <span>{{ itemData.imo_city }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_size')">
                                            <h3>{{ $t('v_long') }} : </h3>
                                            <span>{{ itemData.imo_size }}</span>
                                        </li>
                                        <!--                                        <li v-if="checkCerti()">
                                                                                    <h3>{{ $t('certi2') }}</h3>
                                                                                    <span v-if="checkCerti()">{{ $t('has') }}</span>
                                                                                    <span v-else>{{ $t('empty') }}</span>
                                                                                </li>-->
                                        <li v-if="checkItemData('imo_copyright')">
                                            <h3>{{ $t('copyright_info2') }}</h3>
                                            <span>{{ itemData.imo_copyright }}</span>
                                        </li>
                                    </ul>
                                    <ul class="detail_content content_add" v-else-if="itemData.im_type==='audio'">
                                        <li v-if="checkItemData('imo_date')">
                                            <h3>{{ $t('t_record') }} : </h3>
                                            <span>{{ itemData.imo_date }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_country')">
                                            <h3>{{ $t('c_country') }} : </h3>
                                            <span>{{ itemData.imo_country }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_si')">
                                            <h3>{{ $t('c_si') }} : </h3>
                                            <span>{{ itemData.imo_si }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_city')">
                                            <h3>{{ $t('c_city') }} : </h3>
                                            <span>{{ itemData.imo_city }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_size')">
                                            <h3>{{ $t('v_long') }} : </h3>
                                            <span>{{ itemData.imo_size }}</span>
                                        </li>
                                        <!--                                        <li v-if="checkCerti()">
                                                                                    <h3>{{ $t('certi') }} : </h3>
                                                                                    <span>O</span>
                                                                                </li>-->
                                        <li v-if="checkItemData('imo_copyright')">
                                            <h3>{{ $t('copyright_info2') }}</h3>
                                            <span>{{ itemData.imo_copyright }}</span>
                                        </li>
                                    </ul>
                                    <ul class="detail_content content_add" v-else>
                                        <li v-if="checkItemData('imo_date')">
                                            <h3>{{ $t('t_video2') }}</h3>
                                            <span>{{ itemData.imo_date }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_country')">
                                            <h3>{{ $t('c_country') }}</h3>
                                            <span>{{ itemData.imo_country }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_si')">
                                            <h3>{{ $t('c_si') }}</h3>
                                            <span>{{ itemData.imo_si }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_city')">
                                            <h3>{{ $t('c_city') }}</h3>
                                            <span>{{ itemData.imo_city }}</span>
                                        </li>
                                        <!--                                        <li v-if="checkCerti()">
                                                                                    <h3>{{ $t('certi2') }}</h3>
                                                                                    <span v-if="checkCerti()">{{ $t('has') }}</span>
                                                                                    <span v-else>{{ $t('empty') }}</span>
                                                                                </li>-->
                                        <li v-if="checkItemData('imo_copyright')">
                                            <h3>{{ $t('copyright_info2') }}</h3>
                                            <span>{{ itemData.imo_copyright }}</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <!--                            에디션 목록-->
                            <item-edition-component :item-key="itemData.i_sku"
                                                    v-if="itemData.im_edition === 1 && itemData.i_type==='digital'"></item-edition-component>
                            <!--경매-->
                            <div class="bid_content_box" v-if="itemData.i_status === 'auction'">
                                <div class="content_top">
                                    <ul>
                                        <li v-if="itemData.ia_is_minimum === 1">
                                            <h3 class="sub_title sub_left">{{ $t('mini_bid') }}</h3>
                                            <div class="sub_right">
                                                <!--                                             <div class="sub_logo"><img src="@/assets/image/main/small_logo.png" alt=""></div>-->
                                                <span class="sub_span_mg">{{
                                                        returnCommas(itemData.ia_minimum)
                                                    }} Mg</span><br/>
                                                <span class="sub_span_w">({{
                                                        returnMingle(itemData.ia_minimum)
                                                    }})</span>
                                            </div>
                                        </li>
                                        <li>
                                            <h3 class="sub_title sub_left">{{ $t('max_bid') }}</h3>
                                            <div class="sub_right">
                                                <!--                                            <div class="sub_logo"><img src="@/assets/image/main/small_logo.png" alt=""></div>-->
                                                <span class="sub_span_mg">{{ returnCommas(itemData.i_price) }} Mg</span><br/>
                                                <span class="sub_span_w">({{ returnMingle(itemData.i_price) }})</span>
                                            </div>
                                        </li>
                                        <li v-if="itemData.ia_is_immediately === 1">
                                            <h3 class="sub_title sub_left">{{ $t('i_buy') }}</h3>
                                            <div class="sub_right">
                                                <!--                                            <div class="sub_logo"><img src="@/assets/image/main/small_logo.png" alt=""></div>-->
                                                <span class="sub_span_mg">{{
                                                        returnCommas(itemData.ia_immediately)
                                                    }} Mg</span><br/>
                                                <span class="sub_span_w">({{
                                                        returnMingle(itemData.ia_immediately)
                                                    }})</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div class="btn_wrap" v-if="itemData.ia_auction === 2 && checkMemberType()">
                                    <button class="gobid" @click="openAuctionHistoryModal('auction-history-modal')">
                                        {{ $t('bid_history') }}
                                    </button>
                                    <button class="gobid" @click="checkLogin('bid')">{{ $t('bid') }}</button>
                                </div>
                                <div class="btn_wrap" v-else-if="itemData.ia_auction === 1">
                                    <button class="gobid before_auction">
                                        {{ $t('beforeAuction') }}
                                    </button>
                                </div>
                            </div>


                            <!--              구매가-->
                            <div class="fix_wrap" v-else-if="itemData.i_status === 'pricetag'">
                                <div class="content_top">
                                    <ul>
                                        <li>
                                            <h3 class="sub_title sub_left">{{ $t('n_buy') }}</h3>
                                            <div class="sub_right">
                                                <!--                                            <div class="sub_logo"><img src="@/assets/image/main/small_logo.png" alt=""></div>-->
                                                <span class="sub_span_mg">{{ returnCommas(itemData.i_price) }} Mg</span><br/>
                                                <span class="sub_span_w">({{ returnMingle(itemData.i_price) }})</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="btn_wrap" v-if=" checkMemberType()">
                                    <button class="gobid" @click="checkLogin('price')">{{
                                            $t('buy')
                                        }}
                                    </button>
                                </div>
                            </div>
                            <!--              구매가 끝-->

                            <div class="fix_wrap" v-else-if="itemData.i_status === 'nosale'">
                                <div class="content_top">
                                    <ul>
                                        <li>
                                            <h3 class="sub_title sub_left">{{ $t('n_buy') }}</h3>
                                            <div class="sub_right">
                                                <!--                                            <div class="sub_logo"><img src="@/assets/image/main/small_logo.png" alt=""></div>-->
                                                <span class="sub_span_mg">{{
                                                        returnCommas(itemData.i_price)
                                                    }} Mg</span></br>
                                                <span class="sub_span_w">({{ returnMingle(itemData.i_price) }})</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clear"></div>
                </div>
                <div class="tab_detail_wrap">
                    <div class="item_tab">
                        <ul class="tab_wrap">
                            <li @click="changeTab('item')" :class="{ active : tab === 'item' }" class="item_tab1">
                                <div class="tab">{{ $t('item') }}</div>
                            </li>
                            <li @click="changeTab('block')" :class="{ active : tab === 'block' }"
                                class="blockchain_tab1">
                                <div class="tab">{{ $t('i_block') }}</div>
                            </li>
                            <li @click="changeTab('history')" :class="{ active : tab === 'history' }" class="nft_tab1">
                                <div class="tab">{{ $t('nft_history') }}</div>
                            </li>
                        </ul>
                        <div class="info_detail" v-if=" tab === 'item'">
                            <h3>{{ $t('detail_inform') }}</h3>

                            <ul v-if="itemData.im_type==='photo'">
                                <li v-if="checkItemData('imo_date')">
                                    <h3>{{ $t('t_video2') }}</h3>
                                    <span>{{ itemData.imo_date }}</span>
                                </li>
                                <li v-if="checkItemData('imo_country')">
                                    <h3>{{ $t('v_country2') }}</h3>
                                    <span>{{ itemData.imo_country }}</span>
                                </li>
                                <li v-if="checkItemData('imo_si')">
                                    <h3>{{ $t('v_si2') }}</h3>
                                    <span>{{ itemData.imo_si }}</span>
                                </li>
                                <li v-if="checkItemData('imo_city')">
                                    <h3>{{ $t('v_city2') }}</h3>
                                    <span>{{ itemData.imo_city }}</span>
                                </li>
                                <li v-if="checkItemData('imo_size')">
                                    <h3>{{ $t('size2') }}</h3>
                                    <span>{{ itemData.imo_size }}</span>
                                </li>
                                <li v-if="checkCerti()">
                                    <h3>{{ $t('certi2') }}</h3>
                                    <span v-if="checkCerti()">{{ $t('has') }}</span>
                                    <span v-else>{{ $t('empty') }}</span>
                                </li>
                                <li v-if="checkItemData('imo_copyright')">
                                    <h3>{{ $t('copyright_info2') }}</h3>
                                    <span>{{ itemData.imo_copyright }}</span>
                                </li>
                            </ul>
                            <ul v-else-if="itemData.im_type==='video'">
                                <li v-if="checkItemData('imo_date')">
                                    <h3>{{ $t('t_video2') }}</h3>
                                    <span>{{ itemData.imo_date }}</span>
                                </li>
                                <li v-if="checkItemData('imo_country')">
                                    <h3>{{ $t('v_country2') }}</h3>
                                    <span>{{ itemData.imo_country }}</span>
                                </li>
                                <li v-if="checkItemData('imo_si')">
                                    <h3>{{ $t('v_si2') }}</h3>
                                    <span>{{ itemData.imo_si }}</span>
                                </li>
                                <li v-if="checkItemData('imo_city')">
                                    <h3>{{ $t('v_city2') }}</h3>
                                    <span>{{ itemData.imo_city }}</span>
                                </li>
                                <li v-if="checkItemData('imo_size')">
                                    <h3>{{ $t('v_long') }} : </h3>
                                    <span>{{ itemData.imo_size }}</span>
                                </li>
                                <li v-if="checkCerti()">
                                    <h3>{{ $t('certi2') }}</h3>
                                    <span v-if="checkCerti()">{{ $t('has') }}</span>
                                    <span v-else>{{ $t('empty') }}</span>
                                </li>
                                <li v-if="checkItemData('imo_copyright')">
                                    <h3>{{ $t('copyright_info2') }}</h3>
                                    <span>{{ itemData.imo_copyright }}</span>
                                </li>
                            </ul>
                            <ul v-else-if="itemData.im_type==='audio'">
                                <li v-if="checkItemData('imo_date')">
                                    <h3>{{ $t('t_record') }} : </h3>
                                    <span>{{ itemData.imo_date }}</span>
                                </li>
                                <li v-if="checkItemData('imo_country')">
                                    <h3>{{ $t('c_country') }} : </h3>
                                    <span>{{ itemData.imo_country }}</span>
                                </li>
                                <li v-if="checkItemData('imo_si')">
                                    <h3>{{ $t('c_si') }} : </h3>
                                    <span>{{ itemData.imo_si }}</span>
                                </li>
                                <li v-if="checkItemData('imo_city')">
                                    <h3>{{ $t('c_city') }} : </h3>
                                    <span>{{ itemData.imo_city }}</span>
                                </li>
                                <li v-if="checkItemData('imo_size')">
                                    <h3>{{ $t('v_long') }} : </h3>
                                    <span>{{ itemData.imo_size }}</span>
                                </li>
                                <li v-if="checkCerti()">
                                    <h3>{{ $t('certi') }} : </h3>
                                    <span>O</span>
                                </li>
                                <li v-if="checkItemData('imo_copyright')">
                                    <h3>{{ $t('copyright_info2') }}</h3>
                                    <span>{{ itemData.imo_copyright }}</span>
                                </li>
                            </ul>
                            <ul v-else>
                                <li v-if="checkItemData('imo_date')">
                                    <h3>{{ $t('t_video2') }}</h3>
                                    <span>{{ itemData.imo_date }}</span>
                                </li>
                                <li v-if="checkItemData('imo_country')">
                                    <h3>{{ $t('c_country') }}</h3>
                                    <span>{{ itemData.imo_country }}</span>
                                </li>
                                <li v-if="checkItemData('imo_si')">
                                    <h3>{{ $t('c_si') }}</h3>
                                    <span>{{ itemData.imo_si }}</span>
                                </li>
                                <li v-if="checkItemData('imo_city')">
                                    <h3>{{ $t('c_city') }}</h3>
                                    <span>{{ itemData.imo_city }}</span>
                                </li>
                                <li v-if="checkCerti()">
                                    <h3>{{ $t('certi2') }}</h3>
                                    <span v-if="checkCerti()">{{ $t('has') }}</span>
                                    <span v-else>{{ $t('empty') }}</span>
                                </li>
                                <li v-if="checkItemData('imo_copyright')">
                                    <h3>{{ $t('copyright_info2') }}</h3>
                                    <span>{{ itemData.imo_copyright }}</span>
                                </li>
                            </ul>
                            <div class="description_wrap mb16">
                                <h3>{{ $t('i_inform') }}</h3>
                                <div class="ql-editor" v-html="decodeHtml(replaceBr(itemData.i_memo))">
                                </div>
                            </div>
                            <!--유저 아이템 리스트 -->
                            <div class="nick_item" v-if="itemData.hasOwnProperty('ibc_curator')">
                                <h3>{{ $t('itemMsg', {nick: itemData.curator_nick}) }}</h3>
                                <item-list-component :item-type="'premiumUserItemList'"
                                                     :memberKey="itemData.ibc_curator"></item-list-component>
                            </div>
                        </div>
                    </div>


                    <div class="block_tab" v-if=" tab === 'block'">
                        <!-- 블록체인 정보 -->
                        <block-chain-component :block-data="itemData.BlockChain"></block-chain-component>
                    </div>

                    <div class="history_tab" v-if=" tab === 'history'">
                        <div class="info_detail">
                            <h3>{{ $t('nft_history') }}</h3>
                            <div class="more_view_btn2" @click="moveHistoryMore('history')">More+</div>
                            <div class="clear"></div>
                            <!-- 아이템 거래내역 -->
                            <history-detail-component v-if="itemData.hasOwnProperty('i_sku')"
                                                      :history-type="'itemDetail'"
                                                      :history-key="itemData.i_sku"></history-detail-component>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <play-video-modal></play-video-modal>
        <play-audio-modal></play-audio-modal>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ItemListComponent from "@/components/item/ItemListComponent";
import BlockChainComponent from "@/components/blockChain/BlockChainComponent";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins"
import {mapState} from "vuex";
import LikeComponent from "@/components/like/LikeComponent";
import vClickOutside from 'v-click-outside'
import HistoryListComponent from "@/components/history/HistoryListComponent";
import HistoryDetailComponent from "@/components/history/HistoryDetailComponent";
import EventBus from "@/utils/event-bus";
import AuthMixins from "@/mixins/AuthMixins";
import alertMixins from "@/mixins/alertMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import priceMixins from "@/mixins/priceMixins";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";
import ItemEditionComponent from "@/components/item/ItemEditionComponent";
import PlayVideoModal from "@/components/modal/PlayVideoModal";
import PlayAudioModal from "@/components/modal/PlayAudioModal";

let timeInterval = null;
export default {
    name: "ItemDetailPremiumMarketLayout",
    mixins: [imageOption, dateMixins, AuthMixins, alertMixins, imageResizeMixins, priceMixins, awsS3FileDownload],
    components: {
        ItemEditionComponent,
        HistoryDetailComponent,
        HistoryListComponent,
        LikeComponent,
        BlockChainComponent,
        ItemListComponent,
        PlayVideoModal,
        PlayAudioModal,
        Swiper,
        SwiperSlide,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            itemKey: this.$route.params.idx,
            itemCategorys: {},
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 8,
                grabCursor: true,
                autoWidth: true,
                // loop: true,
            },
            currentTime: '',
            size: {},
            memberData: {},
            tab: 'item',
            mainImg: '',
            spin: false,
            imgHover: false,

            getDataStatus: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab)) {
            this.changeTab('item');
        }
        this.initItemData();
        this.itemCategorys = this.itemCategoryList;
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
        this.initItemData()
    },
    beforeDestroy() {
        clearInterval(timeInterval)
    },
    destroyed() {
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initItemData() {
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile.Detail)) {
                this.thumnailImgs = this.itemData.ItemFile.Detail;
                this.orgItemImg = this.itemData.ItemFile.Item[0].org_url;
                this.mainImg = this.orgItemImg;
                this.thumnailStatus = true;
            } else {
                this.thumnailImgs = [];
                this.mainImg = '';
                this.thumnailStatus = false;
            }
            if (!util.isEmpty(this.itemData) && this.itemData.hasOwnProperty('originator_nick')) {
                EventBus.$emit('setMetaTitle', this.itemData.i_name, this.itemData.originator_nick);
            } else if (!util.isEmpty(this.itemData)) {
                EventBus.$emit('setMetaTitle', this.itemData.i_name);
            }
        },
        returnCommas(x = 0) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        /*returnCommas(x=0) {
          return util.Number.com
        },*/
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        moveUpdate(url) {
            console.log(this.checkDisabled(this.itemData))
            if (this.checkDisabled(this.itemData)) {
                return
            }
            this.$router.push(`${this.$route.path}/${url}`)
        },
        moveHistoryMore() {
            this.$router.push(`/item/${this.itemData.i_sku}/history`);
        },
        checkLogin(url) {
            if (!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            } else {
                this.movePage(url);
            }
        },
        openModal(name) {
            this.$modal.show(name);
        },
        openAuctionHistoryModal() {
            this.$modal.show('auction-history-modal', {m_idx: this.itemData.m_idx});
        },
        changeTab(tab) {
            this.tab = ''
            this.$nextTick(() => {
                this.tab = tab;
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(45, 45, 'png')}`;
            }
            if (type != 'curator') {
                return 'http://testimage.minglemint.com/profile/no_image.png?w=45&h=45&f=png'
            }
        },
        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            this.$forceUpdate();
        },
        returnItemType(data) {
            if (util.isEmpty(data)) {
                return
            }
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (data.i_status === 'auction') {
                if (data.ia_auction === 2) {
                    let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
                    if (diffTime === '00 : 00 : 00' && !this.getDataStatus) {
                        this.getDataStatus = true;
                        setTimeout(() => {
                            this.getDataStatus = false;
                            EventBus.$emit('getDetail');
                        }, 1000 * 30)
                    }
                    return diffTime
                } else {
                    return this.$t('beforeAuction');
                }
            } else if (data.i_status === 'license') {
                return this.$t('license')
            } else if (data.i_status === 'pricetag') {
                return this.$t('fix_selling')
            } else {
                return this.$t('no_sell')
            }
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        decodeHtml(html = '') {
            return util.htmlDecode(html);
        },
        checkCerti() {
            return !util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile.Guaranty) && !util.isEmpty(this.itemData.ItemFile.Guaranty[0].a_idx)
        },
        checkItemData(data) {
            return !util.isEmpty(this.itemData[data]);
        },
        onClickOutside() {
            console.log(123)
        },
        returnCategoryName(data) {
            if (data.ic_idx === 2) {
                return this.$t('art')
            } else if (data.ic_idx === 3) {
                return this.$t('picture')
            } else if (data.ic_idx === 4) {
                return this.$t('video')
            } else if (data.ic_idx === 5) {
                return this.$t('music')
            } else if (data.ic_idx === 9) {
                return this.$t('collections')
            } else if(data.ic_idx === 178){
                return this.$t('prompt')
            }
        },
        getMemberData(data, type) {
            this.memberData = {}
            if (util.isEmpty(data[type])) {
                return
            }
            let params = {
                no: data[type]
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, 'png')}`
            }
        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        moveMember(url) {
            this.$router.push(`/${url}`)
        },
        checkUpdate() {
            if (util.isEmpty(this.itemData)) {
                return false;
            }
            if (util.isEmpty(this.UserInfo)) {
                return false;
            }
            // if (this.itemData.im_status === 2 || this.itemData.im_status === 8) {
            //     if (this.itemData.im_status === 2 && this.itemData.ia_auction === 1) {
            //         if (!util.isEmpty(this.itemData.ibc_curator) && this.itemData.ibc_curator === this.UserInfo.mb_no) {
            //             return true;
            //         }
            //         if (util.isEmpty(this.itemData.ibc_curator) && this.itemData.provider === this.UserInfo.mb_no) {
            //             return true;
            //         }
            //     }
            //     return false;
            // }
            // if (this.itemData.im_status === 1 || this.itemData.im_status === 7) {
            //     if (this.itemData.im_status === 1 && this.itemData.ia_auction === 1) {
            //         if (!util.isEmpty(this.itemData.ibc_curator) && this.itemData.ibc_curator === this.UserInfo.mb_no) {
            //             return true;
            //         }
            //         if (util.isEmpty(this.itemData.ibc_curator) && this.itemData.provider === this.UserInfo.mb_no) {
            //             return true;
            //         }
            //     }
            //     return false;
            // }
            if (!util.isEmpty(this.itemData.ibc_curator) && this.itemData.ibc_curator === this.UserInfo.mb_no) {
                return true;
            }
            if (this.itemData.provider === this.UserInfo.mb_no) {
                return true;
            }
            return false;
        },
        checkDisabled(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.ibc_status) && data.ibc_status === 1 && data.provider === this.UserInfo.mb_no) {
                return true;
            }
            return false;
        },

        checkMemberType() {
            if (!util.isEmpty(this.UserInfo) && this.itemData.provider === this.UserInfo.mb_no || this.UserInfo.g_idx === 2) {
                return false
            }
            return true
        },
        buyItem(type, price) {
            EventBus.$emit('buyItem', type, price);
        },
        checkDetail() {
            return !util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile.Detail)
        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target) || util.isEmpty(event.target.currentSrc)) {
                return false;
            }
            let element = event.target;
            let elementSrc = element.currentSrc.split('?');

            if (util.isEmpty(elementSrc[0])) {
                return false;
            }
            if (elementSrc[0] == this.mainImg) {
                return false;
            }
            this.mainImgLoading = true;
            this.changeThumnail(elementSrc[0]);
        },
        openShareModal() {
            this.$modal.show('share-modal', {itemData: this.itemData})
        },
        returnCategoryDetailName(data) {
            let locale = this.$i18n.locale;
            if (locale === 'ko') {
                return data.ic_name
            }
            return data.ic_name_eng
        },
        returnType(data) {
            if (data === 'real') {
                return `${this.$t('real')}`
            } else {
                return `${this.$t('digi')}`
            }
        },
        checkImg(data) {
            if (data.org_name === 'no_image.png') {
                return false
            }
            return true

        },
        checkImgData(data) {
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile[data]) && !util.isEmpty(this.itemData.ItemFile[data][0].a_idx)) {
                return true
            }
            return false
        },
        awsS3Download(type, name, key, nft) {
            if (util.isEmpty(this.UserInfo) || this.itemData.provider != this.UserInfo.mb_no) {
                return false;
            }
            this.runDownloadFileInfo(type, name, key, nft).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }
            })
        },
        isLoadMainImg() {
            this.mainImgLoading = false;
        },
        spinImg() {
            this.spin = !this.spin;
        },
        imageHover(type) {
            this.imgHover = type;
        },
        isEmpty(data) {
            return util.isEmpty(data);
        },
        isPossiblePlay() {
            return this.orgItemImg === this.mainImg && (this.itemData.im_type === 'video' || this.itemData.im_type === 'audio')
        },
        openPlayModal() {
            if (!this.isPossiblePlay()) {
                return false;
            }
            if (this.itemData.im_type === 'video') {
                this.openVideoModal();
            } else if (this.itemData.im_type === 'audio') {
                this.openAudioModal();
            }
        },
        openVideoModal() {
            if (util.isEmpty(this.itemData.ItemFile) || util.isEmpty(this.itemData.ItemFile.VideoStream)) {
                return false;
            }
            let url = this.itemData.ItemFile.VideoStream[0].org_url;
            let name = this.itemData.ItemFile.VideoStream[0].org_name;
            let thumbnail = this.orgItemImg;
            this.$modal.show('play-video-modal', {'src': url, 'name': name, thumbnail: thumbnail, is360: false});
        },
        openAudioModal() {
            if (util.isEmpty(this.itemData.ItemFile) || util.isEmpty(this.itemData.ItemFile.Original)) {
                return false;
            }
            let url = this.itemData.ItemFile.Original[0].org_url;
            let name = this.itemData.ItemFile.Original[0].org_name;
            let thumbnail = this.orgItemImg;
            this.$modal.show('play-audio-modal', {'src': url, 'name': name, 'thumbnail': thumbnail});
        },
        openGiftModal() {
            this.$modal.show('gift-item-modal', {itemData: this.itemData});
        },
        checkGift() {
            if (this.itemData.provider === this.UserInfo.mb_no && this.itemData.i_status === 'nosale' && util.isEmpty(this.itemData.ibc_curator)) {
                return true;
            }
            return false
        }
    },
    watch: {
        // "$route.fullPath": {
        //     deep: true,
        //     handler: function (val, oldVal) {
        //         if (this.tab != this.$route.query.tab) {
        //             this.changeTab(this.$route.query.tab);
        //         }
        //     }
        // },
        "itemData": {
            deep: true,
            handler: function (val, oldVal) {
                this.initItemData();
            }
        },
    },
}
</script>

<style scoped>

</style>
